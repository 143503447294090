p {
  color: $content-color;
}

.us-section {
  &.us-alternate {
    background-color: $alternate-background-color;
  }

  &.us-dark-colored {
    background-color: $us-app-dark;
    color: white;

    h1, h2, h3, h4, h5, h6 {
      color: $us-app-green;
    }

    p {
      color: white;
    }

    .us-description-header {
      color: $us-app-green;
    }
  }

  &.us-colored {
    background-color: $colored-background-color;

    color: white;

    .us-section-header {
      color: white;
    }
  }

  &.us-colored-alt {
    background-color: $colored-background-color-alt;

    color: white;

    .us-section-header {
      color: white;
    }
  }

  .us-section-header {
    color: $us-app-dark;
  }
}

.footer {

}
