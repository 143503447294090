.list-group {
  &.us-action-list-group {
    box-shadow: 0 0 0.05rem rgba(0, 0, 0, 0.1);

    .list-group-item {
      padding: 32px;

      h1, h2, h3, h4, h5, h6 {
        font-size: 1.33rem;
        font-weight: bold;
      }

      .us-list-icon.fa-stack {
        font-size: 1.25rem;

        .us-list-icon-background {
          color: #f3f3f3;
        }

        .us-list-icon-text {
          color: #7f7f7f;
        }
      }
    }
  }

  &.us-transparent-list-group {
    margin-top: 24px;
    margin-bottom: 24px;

    .list-group-item {
      background: none;

      .us-list-icon {
        font-size: 1.5rem;
      }
    }
  }

  .list-group-item {
    .us-list-icon {
      min-width: 40px;
    }
  }
}

.us-list-group-header {
  margin-top: 24px;
  margin-bottom: 24px;

  padding-left: 1.25rem;
  padding-right: 1.25rem;

  font-size: 1.75rem;
}
