.us-inactive {
  color: $inactive-color;
}

.us-active {
  color: $active-color;
}

.us-palette-1 {
  color: $palette-color-1;
}

.us-palette-3 {
  color: $palette-color-3;
}

.us-palette-5 {
  color: $palette-color-5;
}

.us-palette-7 {
  color: $palette-color-7;
}

.us-palette-9 {
  color: $palette-color-9;
}
