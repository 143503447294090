.us-description {
  padding-top: 10px;
  padding-bottom: 10px;

  height: 180px;

  p {
    font-size: 0.875rem;
  }

  .us-description-icon {
    margin-bottom: 10px;
  }

  .us-description-header {
    text-transform: uppercase;
    margin-bottom: 20px;
    font-size: 1.1rem;
  }
}

.us-description.us-description-bottom {
  padding-top: 10px;
}

/* Gridify this */
@media (min-width: 992px) {
  .us-description {
    padding-top: 20px;

    height: 280px;
  }

  .us-description.us-description-bottom {
    padding-top: 20px;
    padding-bottom: 0;
    height: inherit;
  }
}
