.us-typewriter {
  padding-right: 0.15em;
  margin-right: 10px;
  font-size: 1.33rem;
  font-family: $title-font-family;

  &.us-typewriter-muted {
    color: #6c757d;
    border-right: 0.08em solid #a6b6c2;
  }
}
