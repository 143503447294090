.us-phone-img {
  position: relative;
  top: -70px;
}

.us-controls {
  width: 380px;

  .form-control {
    font-size: 0.9rem;
  }

  .btn {
    font-size: 0.9rem;
  }
}

@media (min-width: 992px) {
  .us-phone-img {
    top: 60px;
  }
}

.us-banner {
  height: 660px;
  color: #ffffff;

  .us-banner-content {
    padding-top: 60px;

    .us-info {
      min-height: 356px;
      padding-left: 0px;
      padding-top: 30px;

      .us-slogan {
        font-size: 1.5em;
        text-align: center;
        font-family: $title-font-family;
      }

      .us-blurb {
        font-size: 1rem;
        text-align: center;
        font-family: $title-font-family;
      }

      @media (min-width: 768px) {
        .us-slogan {
          font-size: 2.2rem;
        }

        .us-blurb {
          font-size: 1.4rem;
        }
      }

      @media (min-width: 992px) {
        .us-slogan {
          font-size: 2.5rem;
        }

        .us-blurb {
          font-size: 1.5rem;
        }
      }
    }

    @media (min-width: 992px) {
      .us-info {
        padding-left: 40px;
        padding-top: 80px;
        max-width: 492px;
      }
    }

    @media (min-width: 1200px) {
      .us-info {
        padding-top: 160px;
        max-width: 672px;
      }
    }
  }
}
