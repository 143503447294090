// UniSalad variables
@import 'variables/variables';

// Bootstrap customization
@import 'bootstrap-theme/custom';

// UniSalad styles
@import 'styles/banner';
@import 'styles/carousel';
@import 'styles/colors';
@import 'styles/dividers';
@import 'styles/features';
@import 'styles/fonts';
@import 'styles/footer';
@import 'styles/list-group';
@import 'styles/logo';
@import 'styles/navigation';
@import 'styles/section';
@import 'styles/themes';
@import 'styles/typewriter';

// Font Awesome
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts/';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';

body {
  font-family: $content-font-family;

  background: $alternate-background-color;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $title-font-family;
}

h1 {
  font-size: 3rem;
  font-weight: 400;
}

p {
  font-size: 1rem;
  color: $content-color;
}

a {
  color: inherit;

  &:link, &:visited, &:hover, &:active {
    color: inherit;
    text-decoration: none;
  }
}

.us-dimmer {
  background-color: rgba(0, 120, 160, 0.6);
}

.us-form-control {
  border: none !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
    -webkit-appearance: none !important;
    border: none !important;
    z-index: auto !important;
  }
}

.us-btn {
  &:disabled {
    opacity: 1 !important;
  }
}

.us-pl-lg-4-5 {
  @media (min-width: 992px) {
    padding-left: 36px;
  }
}

.us-pr-lg-4-5 {
  @media (min-width: 992px) {
    padding-right: 36px;
  }
}

.us-pl-lg-6 {
  @media (min-width: 992px) {
    padding-left: 80px;
  }
}

.us-pr-lg-6 {
  @media (min-width: 992px) {
    padding-right: 80px;
  }
}

.us-pl-lg-7 {
  @media (min-width: 992px) {
    padding-left: 120px;
  }
}

.us-pr-lg-7 {
  @media (min-width: 992px) {
    padding-right: 120px;
  }
}
