.footer {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: $us-app-dark;
  color: #ffffff;

  p {
    color: #ffffff;
  }

  a {
    color: inherit;

    &:hover, &:active {
      color: #d1d1d1;
      text-decoration: none;
    }
  }
}
