.us-carousel {
  .carousel-item {
    height: 280px;
    padding: 40px 20% 40px 20%;
  }
}

.review-rating {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ffff66;
  font-size: 1.5em;
}
