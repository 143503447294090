.navbar {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.list-group-item {
  padding-top: 4px;
  padding-bottom: 4px;
}

.was-validated .form-control:valid {
  border-color: $input-border-color;
}

.was-validated .form-check-input:valid ~ .form-check-label {
  color: $gray-900;
}
