$logo-font-family: 'Mitr', sans-serif;
$title-font-family: 'Source Sans Pro', sans-serif;
$content-font-family: 'Raleway', sans-serif;

$us-app-green: #05c788;
$us-app-blue: #70e3ee;
$us-app-dark: rgb(38, 40, 47);

$us-grey: rgb(120, 121, 126);

$alternate-background-color: rgb(249, 249, 249);
$colored-background-color: $us-app-green;
$colored-background-color-alt: $us-app-blue;

$content-color: #666666;

$inactive-color: #cccccc;
$active-color: #28a745;
$palette-color-1: #5de2a7;
$palette-color-3: #5de2ba;
$palette-color-5: #5de2d0;
$palette-color-7: #5dd9e2;
$palette-color-9: #5db1e2;
